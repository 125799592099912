import { companyMixin } from '@/assets/mixins/company'
import { workorderMixins } from '@/assets/mixins/workorder'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getTaskitemType } from '@/api/msp/status'

import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { formartMspTaskitemRephotoStatus } from '@/utils/tagStatus'
export const indexMixins = {
  mixins: [companyMixin, workorderMixins],
  data () {
    return {
      curListTab: 1, // 当前显示列表，1-质检，2-对账
      companyId: this.$store.getters.token.userInfo.companyId,
      pagesizeSetting: [15, 50, 100, 200, 500],
      companyAssetArray: [],
      stationArray: [],
      taskitemTypeArray: [],
      adTypeArray: [],
      actionDateRange: [],
      selectedItem: [],
      curTaskInfo: {},
      materialView: false,
      defineColumns: {
        selection: {
          type: 'selection',
          width: 60,
          align: 'center',
          fixed: 'left',
          key: 'selection'
        },
        code: {
          title: '资源编号',
          key: 'resourceCode',
          minWidth: 120,
          render: (h, { row }) => {
            return h('span', row.resourceCode || '/')
          }
        },
        position: { title: '位置', key: 'location', align: 'center', minWidth: 140 },
        typeName: { title: '任务类型', align: 'center', key: 'taskitemTypeName', minWidth: 90 },
        actionDate: { title: '作业日期', align: 'center', key: 'actionDate', minWidth: 120 },
        adTypeName: { title: '用途', key: 'adTypeName', align: 'center', minWidth: 100 },
        remark: { title: '备注', align: 'center', key: 'remark', minWidth: 120, ellipsis: true, tooltip: true },
        createCompanyName: {
          title: '发起方',
          key: 'createCompanyName',
          minWidth: 140,
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.createCompanyName || '/')
          }
        },
        supplierCompany: {
          title: '供应商',
          key: 'supplierCompanyName',
          minWidth: 140,
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.supplierCompanyName || '/')
          }
        },
        actionFile: {
          title: '素材',
          minWidth: 100,
          align: 'center',
          render: (h, { row }) => {
            if (row.taskitemFiles && row.taskitemFiles.length > 0) {
              if (row.taskitemFiles.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      this.curTaskInfo = row
                      this.onViewMaterial()
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          this.curTaskInfo = row
                          this.onViewMaterial()
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无素材')
            }
          }
        },
        customer: {
          title: '客户',
          align: 'center',
          key: 'advertiserName',
          minWidth: 120,
          render: (h, { row }) => {
            return h('span', row.advertiserName || '/')
          }
        },
        skuName: {
          title: '单品',
          key: 'skuName',
          minWidth: 100,
          align: 'center',
          render: (h, { row }) => {
            if (row.skuId > -1) {
              return h('a', {
                on: {
                  click: () => {
                    this.onShowSkuTask(row)
                  }
                }
              }, row.skuName)
            } else {
              return h('span', '/')
            }
          }
        },
        schedule: {
          title: '订单档期',
          minWidth: 160,
          key: 'schedule',
          align: 'center',
          render: (h, { row }) => {
            return h('span',
              GetCurrentSchedule(row.startDate, row.endDate, false)
            )
          }
        },
        chargeUnitCost: {
          title: '计费单价',
          minWidth: 180,
          align: 'center',
          key: 'charge',
          render: (h, { row }) => {
            const html = [
              h('span', `${row.costTypeName} : `),
              h('span', {
                style: { color: '#44bd32', fontWeight: 'bold' }
              }, toMoney(row.confirmChargeUnitCost)),
              h('span', ' / ' + row.confirmChargeTypeName)
            ]
            return h('div', html)
          }
        },
        chargeQuantity: {
          title: '计费量',
          minWidth: 120,
          align: 'center',
          render: (h, { row }) => {
            return h('span', `${row.confirmChargeQuantity}  ${row.confirmChargeTypeName}`)
          }
        },
        chargeTotalFee: {
          title: '计费金额',
          minWidth: 100,
          align: 'center',
          render: (h, { row }) => {
            return h('span', {
              style: { color: '#44bd32', fontWeight: 'bold' }
            }, toMoney(row.confirmCost))
          }
        },
        qualityTestTime: {
          title: '质检时间',
          key: 'qualityTestTime',
          align: 'center',
          minWidth: 140,
          render: (h, { row }) => {
            return h('span', row.qualityTestTime || '/')
          }
        },
        reconTime: {
          title: '对账时间',
          key: 'settlementTime',
          align: 'center',
          minWidth: 140,
          render: (h, { row }) => {
            return h('span', row.settlementTime || '/')
          }
        },
        checkUser: {
          title: '质检员',
          key: 'qualityTestUserName',
          align: 'center',
          minWidth: 90,
          render: (h, { row }) => {
            return h('span', row.qualityTestUserName || '/')
          }
        },
        status: {
          title: '状态',
          width: 120,
          key: 'status',
          align: 'center',
          render: (h, { row }) => {
            const html = []
            html.push(
              h('Tag', {
                props: { color: row.qualityTesting ? 'success' : 'default' }
              }, row.qualityTesting ? '已质检' : '未质检')
            )
            if (row.rephoto > 0) {
              html.push(formartMspTaskitemRephotoStatus(h, row.rephoto))
            }

            return h('div', html)
          }
        },
        chargeStatus: {
          title: '状态',
          width: 120,
          key: 'status',
          align: 'center',
          render: (h, { row }) => {
            return h('Tag', {
              props: { color: row.taskitemChargeStatus === 2 ? 'success' : 'default' }
            }, row.taskitemChargeStatus === 2 ? '已对账' : '未对账')
          }
        },
        operate: {
          title: '操作',
          minWidth: 180,
          fixed: 'right',
          key: 'operate',
          align: 'center',
          render: (h, { row }) => {
            const operation = []

            if (row.taskitemType === 6) { // 测量任务
              if (this.curListTab === 1) { // 质检
                operation.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      // this.$refs.SvgMaintenanceTask.initPageData(row, true)
                      this.$refs.measureTaskDetail.showModal(row)
                    }
                  }
                }, '详情'))
              } else { // 对账
                operation.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$refs.taskDetail.showModal(row)
                    }
                  }
                }, '详情'))
              }
            } else {
              operation.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$refs.taskDetail.showModal(row)
                  }
                }
              }, '详情'))
              if (this.curListTab === 1 && row.resourcetypeId === 400 && !row.qualityTesting && this.isAuth('inspection_index_train')) { // 列车已完成上刊任务
                operation.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.onConfirmTrain(row)
                    }
                  }
                }, '确认列车'))
              }
              if (this.curListTab === 1 && [-1, 2].includes(row.taskitemFileFillWay) && this.isAuth('inspection_index_remake')) {
                operation.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleSetFiles(row)
                    }
                  }
                }, '素材补充')
                )
              }
            }
            if (this.curListTab === 1 && [1, 3, 7, 11].includes(row.taskitemType) && row.picturesource === 1) {
              // 上刊，下刊，制作，画面填充任务查看关联任务
              operation.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleShowTask(row)
                  }
                }
              }, '关联任务'))
            }
            // if (this.elecDeviceIds.includes(params.row.resourcetypeId) && params.row.status === 2 && [1, 2].includes(params.row.type)) {
            //   // 状态为执行中的电子屏上刊和监播任务
            //   operation.push(h('a', {
            //     style: { marginRight: '5px' },
            //     on: {
            //       click: () => {
            //         this.onSetFeedbackPic(params.row)
            //       }
            //     }
            //   }, '设置反馈素材'))
            // }
            if (this.curListTab === 2 && row.taskitemChargeStatus === 1 && this.isAuth('inspection_index_fee')) { // 对账列表，未对账
              operation.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.onAdjustCost(row)
                  }
                }
              }, '调整'))
            }

            return h('div', operation)
          }
        }
      }
    }
  },
  methods: {
    onChangeAsset () {
      this.query.stationId = undefined
      if (this.query.assetId) {
        this.query.pageNumber = 1
        this.getStationData()
      } else {
        this.stationArray = []
      }
    },
    /**
     * 获取站点集合
     */
    async getStationData () {
      const result = await getRimStationByAssetIds({ assetIds: this.query.assetId ? JSON.stringify([this.query.assetId]) : '' })
      this.stationArray = result && !result.errcode ? result : []
    },
    /**
     * 获取任务类型集合
     */
    async getTaskitemTypeData () {
      const result = await getTaskitemType()
      this.taskitemTypeArray = result && !result.errcode ? result : []
    },
    onChangeActionDate (dates) {
      if (Array.isArray(dates) && dates.length) {
        this.query.startActionDate = dates[0]
        this.query.endActionDate = dates[1]
      } else {
        this.query.startActionDate = ''
        this.query.endActionDate = ''
      }
    },
    onChangeTaskType () {
      this.query.adType = null
      this.adTypeArray = []
      this.getOrderAdTypeData(this.query.taskitemType).then(res => {
        this.adTypeArray = res
      })
    },
    onSelectionTable (selections) {
      this.selectedItem = selections.map(x => x.id)
    },
    /**
     * 查看素材
     */
    onViewMaterial () {
      this.materialView = true
      this.$nextTick(() => {
        this.$refs.materialView.showModal(this.curTaskInfo)
      })
    },
    onShowSkuTask (row) {
      this.$nextTick(() => {
        this.$refs.skuTask.showModal(row)
      })
    },
    onDownloadTask () {
      this.$nextTick(() => {
        this.$refs.downloadTask.showModal()
      })
    }

  }
}
