import { remakePhoto } from '@/api/msp/taskitem'
export const optionMixins = {
  data () {
    return {
      remakeRemark: '',
      curTaskitemIds: []
    }
  },
  methods: {
    /**
     * 重拍
     */
    onRephoto () {
      if (this.curTaskitemIds.length === 0) {
        this.$Notice.error({ desc: '非法访问，缺少必要参数' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        render: (h) => {
          return h('div', [
            h('p', {
              style: { marginBottom: '10px' }
            }, '确认信息无误，并发起重拍？'),
            h('Input', {
              props: {
                value: this.remakeRemark,
                autofocus: true,
                size: 'small',
                type: 'textarea',
                rows: 2,
                placeholder: '填写重拍备注（非必填）'
              },
              on: {
                input: (val) => {
                  this.remakeRemark = val
                }
              }
            })
          ])
        },
        onOk: () => {
          const postData = {
            taskitemIds: JSON.stringify(this.curTaskitemIds),
            remark: this.remakeRemark
          }

          remakePhoto(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '已成功发起重拍任务' })
              this.curTaskitemIds = []
              this.remakeRemark = ''
              this.updateTable()
            } else {
              this.$Notice.error({ title: '重拍任务操作失败', desc: res.errmsg })
            }
          })
        }
      })
    }
  }
}
